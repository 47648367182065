import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";

function CGV() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pb-5 pb-5">
        <h1 className="custom-page-title pt-5">
          Politique Générale de Ventes <br /> de KASOA
        </h1>
        <div className="container">
          <div className="custom-politique-page-content pt-5">
            <h3 className="custom-sm-title pb-1 pb-1">
              Politique Générale de Ventes de KASOA
            </h3>
            <div className="content-all-politique pt-4 pb-3">
              <div className="content-politique-item pt-5">
                <h3 className="custom-sm-title pb-2">
                  1. Commandes et Paiements
                </h3>
                <div className="custom-sm-value">
                  <div className="content-politique-item">
                    <h3 className="custom-sm-title pb-1">
                      1.1. Passer une Commande :
                    </h3>
                    <div className="custom-sm-value">
                      Lorsque vous passez une commande sur notre site, vous
                      acceptez d'acheter les produits ou services sélectionnés
                      aux prix et conditions indiqués.
                    </div>
                  </div>
                  <div className="content-politique-item pt-3">
                    <h3 className="custom-sm-title pb-1">1.2. Paiements :</h3>
                    <div className="custom-sm-value">
                      Les paiements sont traités de manière sécurisée via des
                      méthodes de paiement en ligne sécurisées. Les informations
                      de paiement sont cryptées et protégées.
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-politique-item pt-5">
                <h3 className="custom-sm-title pb-4">
                  2. Livraison et Frais de Port
                </h3>
                <div className="custom-sm-value">
                  <div className="content-politique-item">
                    <h3 className="custom-sm-title pb-1">2.1. Livraison :</h3>
                    <div className="custom-sm-value">
                      <ul>
                        <li>
                          Standard (Kasoa Express) : Livraison sous <strong>24 à 48 heures</strong> dans les zones urbaines.
                        </li>
                        <li>
                          Livraison express : Livraison sous <strong>3 à 6 heures</strong> pour les commandes passées
                          avant <strong>12 h</strong>.
                        </li>
                        <li>
                          Zones semi-urbaines et rurales : Livraison sous <strong>48 à 72 heures</strong>, en fonction de
                          la localisation.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="content-politique-item pt-3">
                    <h3 className="custom-sm-title pb-1">
                      2.2. Frais de Port :
                    </h3>
                    <div className="custom-sm-value">
                      Les frais de port sont calculés en fonction du poids, de
                      la taille et de la destination de votre commande. Les
                      frais de port seront indiqués avant la livraison.
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-politique-item pt-5">
                <h3 className="custom-sm-title pb-4">
                  3. Politique de Retour et de Remboursement
                </h3>
                <div className="custom-sm-value">
                  <div className="content-politique-item">
                    <h3 className="custom-sm-title pb-1">3.1. Retours :</h3>
                    <div className="custom-sm-value">
                      <ul>
                        <li>
                          Produits alimentaires (frais et périssables) : Retour accepté sous <strong>24 heures</strong> après
                          livraison en cas de défaut ou de non-conformité.
                        </li>
                        <li>
                          Produits non alimentaires : Retour accepté sous <strong>7 jours</strong> à condition que l’article
                          soit intact, non utilisé, et dans son emballage d’origine.
                        </li>
                      </ul>

                    </div>
                  </div>
                  <div className="content-politique-item pt-3">
                    <h3 className="custom-sm-title pb-1">
                      3.2. Remboursements :
                    </h3>
                    <div className="custom-sm-value">
                      <ul>
                        <li>
                          Remboursement après validation du retour produit : Traitement
                          sous 5 à 7 jours ouvrables.
                        </li>
                        <li>
                          Mode de remboursement : Par Mobile Money, virement bancaire
                          ou crédit dans le portefeuille Kasoa Pay
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-politique-item pt-5">
                <h3 className="custom-sm-title pb-4">4. Garantie</h3>
                <div className="custom-sm-value">
                  <div className="content-politique-item">
                    <h6 className="custom-sm-value pb-1 fs-14">
                      4.1. Produits alimentaires (emballés) : Garantie limitée à la date d’expiration indiquée sur l’emballage.
                    </h6>
                    <div className="pb-1 custom-sm-value">
                      Conditions générales :
                      <ul>
                        <li>
                          Les retours et remboursements doivent être accompagnés d’une preuve d’achat valide
                          (facture ou numéro de commande)
                        </li>
                        <li>
                          Les articles soldés ou personnalisés ne sont pas remboursables sauf en cas de défaut avéré.
                        </li>
                        <li>
                          Les produits endommagés par une mauvaise utilisation ou installation ne sont pas couverts
                          par la garantie.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-politique-item pt-5">
                <h3 className="custom-sm-title pb-4">5. Responsabilité</h3>
                <div className="custom-sm-value">
                  <div className="content-politique-item">
                    <h3 className="custom-sm-title pb-1">
                      5.1. Limitation de Responsabilité :
                    </h3>
                    <div className="custom-sm-value">
                      Limitation de Responsabilité : Nous déclinons toute
                      responsabilité pour tout dommage direct, indirect,
                      spécial, consécutif ou punitif résultant de l'utilisation
                      de nos produits ou services.
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-politique-item pt-5">
                <h3 className="custom-sm-title pb-1">
                  6. Modifications de la Politique
                </h3>
                <div className="custom-sm-value">
                  Nous nous réservons le droit de modifier cette Politique à
                  tout moment. Les modifications seront effectives dès leur
                  publication sur notre site.
                </div>
              </div>
              <div className="content-politique-item pt-5">
                <h3 className="custom-sm-title pb-1">7. Contact</h3>
                <div className="custom-sm-value">
                  Pour toute question concernant notre Politique Générale de
                  Ventes, veuillez nous contacter via les coordonnées fournies
                  sur notre site.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default CGV;
