import photo1 from "../../../assets/images/appImages/member-1.png";
import photo2 from "../../../assets/images/appImages/member-2.png";
import photo3 from "../../../assets/images/appImages/member-3.png";
import photo4 from "../../../assets/images/appImages/member-4.png";
import photo5 from "../../../assets/images/appImages/member-5.png";

export const TeamData = [
  {
    id: 1,
    photo: photo1,
    name: "Djawad ABDOULAYE",
    role: "Fondateur et Directeur Général",
  },
  {
    id: 2,
    photo: photo2,
    name: "Abibulahi OSSENI",
    role: "Responsable Produit",
  },
  {
    id: 3,
    photo: photo3,
    name: "Kodjo B. AKIBODE",
    role: "Responsable Comptable",
  },
  {
    id: 4,
    photo: photo4,
    name: "Fawzane SALIFOU",
    role: "Graphiste",
  },
  {
    id: 5,
    photo: photo5,
    name: "Credo SOUMAGBO",
    role: "Responsable Commercial",
  },

]
