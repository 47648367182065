import React from "react";
import { FaUser } from "react-icons/fa";
import UseLoginForm, { useSocialLogin } from "../requestForm/UseLoginForm";
import ErrorMessage from "../../common/ErrorMessage";
import { RiLockFill } from "react-icons/ri";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleClientId } from "../../../utils/http";
import { FcGoogle } from "react-icons/fc";
import LoaderSuspenseSmall from "../../common/LoaderSuspenseSmall";

function LoginTab({ closeModalConnexion }) {
  const { register, errors, isLoading, onSubmit, handleChangePhone, phone } =
    UseLoginForm(closeModalConnexion);
  const [isLodingSocialLogin, setIsLodingSocialLogin] =
    React.useState<boolean>(false);

  const { loginWithGoogle } = useSocialLogin(
    closeModalConnexion,
    setIsLodingSocialLogin
  );

  const [showPassword, setShowPassword] = React.useState(false);

  const [showPhone, setShowPhone] = React.useState(false);

  return (
    <>
      <div className="social-connect-content d-flex align-items-center justify-content-center ">
        {/* <LoginSocialFacebook
            isOnlyGetToken
            appId={process.env.REACT_APP_FB_APP_ID || ''}
            onLoginStart={() => console.log("login")}
            onResolve={loginWithGoogle}
            onReject={(err) => {
              console.log(err)
            }}
          >
            <button
              type="button"
              className="btn btn-social-connect d-flex align-items-center gap-2">
              <FaFacebookF size={20} />
              Se connecter avec Facebook
            </button>
        </LoginSocialFacebook> */}
        {!isLodingSocialLogin ? (
          <LoginSocialGoogle
            client_id={GoogleClientId}
            isOnlyGetToken={false}
            onLoginStart={() => console.log("login")}
            onResolve={loginWithGoogle}
            onReject={(err) => {
              console.log(err);
            }}
            scope="profile email"
          >
            <button
              type="button"
              className="btn btn-social-connect d-flex align-items-center gap-2"
            >
              <FcGoogle size={20} />
              Se connecter avec Google
            </button>
          </LoginSocialGoogle>
        ) : (
          <div className="loader-small">
            <LoaderSuspenseSmall />
          </div>
        )}
      </div>
      <div className="or-content text-center">
        <span>Ou</span>
      </div>
      <form id="auth-form" onSubmit={onSubmit}>
        <div className="row auth-form-row">
          <div className="col-md-12 auth-input-col mb-3">
            {!showPhone && (
              <div className="auth-form-group">
                <span className="icon-input">
                  <FaUser />
                </span>

                <input
                  type={`email`}
                  className="form-control auth-form-control"
                  id="email-or-username"
                  placeholder="Email"
                  aria-label="email"
                  {...register("email")}
                />
              </div>
            )}
            {showPhone && (
              <PhoneInput
                inputProps={{
                  name: "phone",
                }}
                defaultMask={".. ... .. .."}
                country="tg"
                value={phone}
                enableSearch={true}
                onChange={(phone, country) => handleChangePhone(phone, country)}
                inputClass="form-control auth-form-control-telephone"
                enableAreaCodes={true}
                prefix="+"
                countryCodeEditable={false}
              />
            )}
            <NavLink
              to="#"
              onClick={() => setShowPhone(!showPhone)}
              className="forget-password-link"
            >
              {!showPhone
                ? "Utiliser mon numero de téléphone"
                : "Utiliser mon addresse e-mail"}
            </NavLink>
            <ErrorMessage message={errors?.email && errors?.email?.message} />
          </div>
          <div className="col-md-12 auth-input-col mb-2">
            <div className="auth-form-group">
              <span className="icon-input">
                <RiLockFill />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                className="form-control auth-form-control"
                id="password"
                placeholder="Mot de passe"
                aria-label="Password"
                {...register("password")}
              />
              <span
                className="show-hide-password"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
            <ErrorMessage
              message={errors?.password && errors?.password?.message}
            />
          </div>
          <div className="remember_me_and_forgot_password py-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="form-check custom_check__content d-flex align-items-center p-0">
                <input
                  className="form-check-input m-0 "
                  type="checkbox"
                  id="remind_me_check"
                  {...register("remember_me")}
                  defaultChecked={true}
                />
                <label className="form-check-label" htmlFor="remember__me">
                  Se souvenir de moi
                </label>
              </div>
              <NavLink
                to={"/mot-de-passe-oublie"}
                className="forget-password-link"
              >
                Mot de passe oublié ?
              </NavLink>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center pt-5">
            <button className="btn auth-submit-btn trans-0-2" role="button">
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Connexion...</span>
                </>
              ) : (
                "Connexion"
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default LoginTab;
