import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import { TeamData } from "./data";


export const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
    </div>
  );
};



function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>

              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">KASOA</h3>
                <div className="custom-content">
                  Kasoa est une plateforme e-commerce innovante, dédiée à la vente de produits alimentaires et essentiels au Togo.
                  Nous facilitons l’accès rapide et sécurisé aux produits de qualité tout en offrant des options de livraison
                  efficaces et des solutions de paiement pratiques.
                  <br />
                  Kasoa incarne la simplicité et l’efficacité dans le commerce électronique au Togo. Grâce à notre plateforme
                  intuitive et nos services intégrés (Kasoa Xpress, KPay, Kasoa Plus), nous transformons l’achat en ligne en une
                  expérience fluide, sécurisée et enrichissante.
                </div>
              </div>
              <div className="col-lg-12 mb-4">
                <div className="pt-3 custom-content-wrapper">
                  <div className="pb-3 para custom-content">
                    <strong>Valeur </strong><br />
                    <ul className="pt-2 list-wrapper">
                      <li className="pb-2 custom-content">
                        <strong>Accessibilité : </strong>Offrir des services abordables et disponibles pour tous.
                      </li>
                      <li className="pb-2 custom-content">
                        <strong>Fiabilité : </strong>Garantir la qualité des produits et des livraisons rapides.
                      </li>
                      <li className="pb-2 custom-content">
                        <strong>Innovation : </strong>Utiliser des technologies modernes pour simplifier l’expérience
                        d’achat.
                      </li>
                      <li className="pb-2 custom-content">
                        <strong>Proximité : </strong>Être à l’écoute des besoins locaux et promouvoir des produits
                        adaptés.
                      </li>
                      <li className="custom-content">
                        <strong>Engagement : </strong>Soutenir l’économie locale et encourager des pratiques durables.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="my-5 our-team gray_sky">
            <div className="container">
              <CustomBeautyTitle title="Team" />
              <h3 className="custom-title">Notre équipe</h3>
              <div className="row pt-4">
                {!!TeamData &&
                  TeamData.map((member, key) => {
                    return (
                      <div className="col-lg-3 col-md-4 mb-4 card-wrapper" key={key}>
                        <TeamCard
                          photo={member.photo}
                          name={member.name}
                          role={member.role}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="my-5 py-5 founder-words gray_sky">
            <div className="container">
              <CustomBeautyTitle title="Qui sommes nous" />
              <h3 className="custom-title">Mot du fondateur</h3>
              <div className="row pt-4">
                <div className="col-md-12 col-lg-12 col-wrapper">
                  <div className="founder-card green_colgate__card">
                    <div className="custom-content">
                      "Kasoa est bien plus qu’une simple plateforme de commerce électronique. C’est un pont entre la modernité
                      et les besoins locaux. Mon ambition est de transformer la manière dont les Togolais achètent leurs produits
                      essentiels en offrant une solution rapide, fiable et adaptée à leur quotidien. Grâce à Kasoa, nous donnons
                      aux communautés locales un accès simplifié aux produits tout en soutenant l’économie nationale.
                      Ensemble, bâtissons un avenir où chaque commande rapproche nos utilisateurs de la commodité et
                      de l’innovation." <br /><br />
                      <span>
                        <strong>Fondateur – <em>Djawad ABDOULAYE</em></strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div style={{ padding: '5rem 0' }}></div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
