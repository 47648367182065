import { ReactElement } from "react";
import { useLocation, Navigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../redux/hook";
import { IUser, UserType } from "../utils/api/user/user.type";
import { isAdmin, isPreparateur, isUser, isAcheteur } from "../utils/Utils";
import { useGetVendeurByApiKeyQuery } from "../utils/api/product/product.api";
import { VendeurApikey } from "../utils/http";

interface ProtectedRoutesProps {
  children: ReactElement;
  userType: UserType[];
} 

export const ProtectedRoutes = ({
  children,
  userType,
}: ProtectedRoutesProps) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);

  const isAccess = userType?.includes(user?.user_type);

  return isAccess && isAccess !== undefined ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path: string = "/";


  if (isUser(user)) {
    path = "/mon-compte/profil";
  } else if (isAdmin(user)) {
    path = "/produits";
  } else if (isAcheteur(user)) {
    path = "/produits";
  }

  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

// UserRoutes
export const IsUserAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  const [params] = useSearchParams();
  let next = params.get("next");
  let path: string = params.get("next") || "/";
  if (token) {
    if (isAdmin(user)) {
      path = "/admin/dashboard";
    }

    if (isAcheteur(user)) {
      path = "/produits";
    }
  }

  // console.log("path",path)

  // return children;
  return !token || !isUser(user) || next ? (
    <Navigate to={path} replace />
  ) : (
    children
  );
};

export const IsAdminAllow = ({ children }: { children: ReactElement }) => {
  const { user, token } = useAppSelector((state) => state.user);
  let path: string = "/connexion";
  if (token) {
    if (isUser(user)) {
      path = "/mon-compte/profil";
    }

    if (isPreparateur(user)) {
      path = "/préparateur/commandes";
    }
  }

  // return children;
  return !token || !isAdmin(user) ? <Navigate to={path} replace /> : children;
};

export const RedirectPageNotAvailable = ({
  children,
}: {
  children: ReactElement;
}) => {
  const { data: vendeur , isLoading } = useGetVendeurByApiKeyQuery(VendeurApikey);


  const location = useLocation();
  let path = location.pathname;

  if (vendeur && !vendeur?.is_active) {
    path = "/site-non-disponible";
  }

  return vendeur?.is_active ? children : <Navigate to={path} replace />;
};
