import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import UseEditInfosForm from "../../admin/ProfilAdmin/requestForm/UseEditInfosForm";
import ErrorMessage from "../../common/ErrorMessage";

const ProfilPage = () => {
  const {
    register,
    handelImageChange,
    handleChangePhone,
    phone,
    avatar,
    onSubmit,
    isLoading,
    errors,
    user,
  } = UseEditInfosForm();
  return (
    <div className="form-register-tabs-component">
      <div className="content-title-form-register pb-5">
        <h3 className="user-parcours-title">Mon profil</h3>
      </div>
      <p className="sub-title-form-register">Information personnelles</p>
      <div className="content-form-register-tabs mt-5">
        <form onSubmit={onSubmit}>
          <div className="row row-add">
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  PRÉNOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                />
                <ErrorMessage
                  message={errors?.prenom && errors?.prenom?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  NOM*
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                />
                <ErrorMessage message={errors?.nom && errors?.nom?.message} />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  Adresse mail
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  disabled
                />
                <ErrorMessage
                  message={errors?.email && errors?.email?.message}
                />
              </div>
            </div>
            <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  TÉLÉPHONE*
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                  }}
                  defaultMask={".. ... .. .."}
                  country="tg"
                  value={phone}
                  enableSearch={true}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                  inputClass="form-control-register-tabs-tel"
                  enableAreaCodes={true}
                  prefix="+"
                  countryCodeEditable={false}
                />
                <ErrorMessage
                  message={errors?.telephone && errors?.telephone?.message}
                />
              </div>
            </div>
            {/* <div className="col-md-6 col-form-register-tabs mb-3">
              <div className="content-col-form-register-tabs">
                <label className="form-label form-label-register-tabs">
                  DATE DE NAISSANCE
                </label>
                <input
                  className="form-control form-control-register-tabs"
                  type="date"
                  placeholder="JJ/MM/AAAA"
                  {...register("birthDate")}
                />
                <ErrorMessage
                  message={errors?.birthDate && errors?.birthDate?.message}
                />
              </div>
            </div> */}
          </div>
          <div className="row py-5">
            <div className="col-md-12 col-lg-12 text-start">
              <div className="content-btn-submit">
                <button type='submit' className="btn custom-btn-theme" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>ENREGISTREMENT...</span>
                    </>
                  ) : (
                    "ENREGISTRER"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfilPage;
